import service from '@/until/api'

export function _FindFaultPageData(data) {
    return service({
        url: '/report-data/carFaultAnalysis/carFaultAnalysisTotal',
        method: 'POST',
        data
    })
}

export function _FindCarHistoryDetail(data) {
    return service({
        url: '/report-data/carHistory/detail',
        method: 'POST',
        data
    })
}

<template>
	<div>
		<el-form :inline="true">
			<el-form-item>
				<el-select
					v-model="searchParams.parkId"
					:clearable="true"
					@change="GetPageData"
					placeholder="请选择园区"
					class="centerSelInput"
				>
					<el-option v-for="item in ParkOption" :key="item.val" :label="item.text" :value="item.val">
					</el-option>
				</el-select>
			</el-form-item>
			
			<el-form-item>
				<el-input
					:clearable="true"
					@clear="GetPageData"
					v-model="searchParams.carType"
					placeholder="请输入车辆类型"
					class="centerInput"
				></el-input>
			</el-form-item>
			
			<el-form-item>
				<el-input
					:clearable="true"
					@clear="GetPageData"
					v-model="searchParams.carNumber"
					placeholder="请输入车辆编号"
					class="centerInput"
				></el-input>
			</el-form-item>
			
			<el-form-item>
				<el-date-picker
					v-model="searchParams.dateRange"
					:clearable="true"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd"
					class="centerDatepicker"
					@change="GetPageData"
				>
				</el-date-picker>
			</el-form-item>
			
			<el-form-item>
				<button class="centerCzbtn" @click="GetPageData">搜索</button>
			</el-form-item>
		</el-form>
		
		<el-card class="car-info-container">
			<div class="analysis">
				<el-row>
					<el-col :span="6">
						<div class="analyAfter">
							<div>综合故障次数</div>
							<div>{{ faultConfig.compreErrorNum }}次</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="analyAfter">
							<div>平均每公里故障次数</div>
							<div>{{ faultConfig.avgKmErrorNum }}次</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="analyAfter">
							<div>主要故障项目</div>
							<div>{{ faultConfig.mainError }}</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="analyAfterLast">
							<div>主要故障项目告警出现次数</div>
							<div>{{ faultConfig.mainErrorNum }}次</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</el-card>
		
		<div class="car-list" style="margin-top: 20px; padding: 0 10px;">
			<p class="list-title"><span></span>车辆故障分析</p>
			
			<el-row :gutter="10" style="margin-top: 20px; ">
				<el-col class="item" :span="12" v-for="(item, idx) in faultConfig.carList" :key="item.carNumber">
					<el-card class="car-info-container">
						<div class="left">
							<img src="@/assets/images/car.png" alt="">
						</div>
						<div class="center">
							<el-row>
								<el-col :span="8" class="title">车辆编号：</el-col>
								<el-col :span="16" class="content">{{ item.carNumber }}</el-col>
							</el-row>
							
							<el-row>
								<el-col :span="8" class="title">车辆型号：</el-col>
								<el-col :span="16" class="content">{{ item.carTypeStr }}</el-col>
							</el-row>
							
							<el-row>
								<el-col :span="8" class="title">所属园区：</el-col>
								<el-col :span="16" class="content">{{ item.parkName }}</el-col>
							</el-row>
							
							<el-row>
								<el-col :span="8" class="title">最近上线：</el-col>
								<el-col :span="16" class="content">{{ item.lastOnlineDate }}</el-col>
							</el-row>
						</div>
						<div class="right">
							<div class="btn-analysis" @click="OpenCarAnalysisDrawer">诊断分析</div>
						</div>
					</el-card>
				</el-col>
			</el-row>
		</div>
		
		<!--region 诊断分析-->
		<el-drawer
			title="车辆诊断分析"
			:visible.sync="carAnalysisConfig.visible"
			:size="carAnalysisConfig.size"
			:destroy-on-close="true"
		>
			<div class="drawer-container">
				<div>
					<div class="dc-left">
						<div class="content-item item-one">
							<p class="left-title">分析信号数据类</p>
							<p class="left-content">
								<el-checkbox v-model="checked">显示全部信号类</el-checkbox>
							</p>
						</div>
						
						<div class="content-item">
							<p class="left-title">分析时间</p>
						</div>
					</div>
					<div class="dc-center">
						<div class="content-item item-one">
							<div class="car-img">
								<img src="../../assets/images/car.png" alt="">
							</div>
							<div class="car-info">
								<p class="car-name">SV10008</p>
								<p class="park-name">东风技术中心园区</p>
								<div class="car-del">
									<i class="el-icon-delete"></i> 删除
								</div>
							</div>
						</div>
						
						<div class="content-item item-two" style="padding-left: 70px;">
							<el-date-picker
								v-model="searchParams.dateRange"
								:clearable="true"
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyy-MM-dd"
								style="border: 0;"
							>
							</el-date-picker>
						</div>
					</div>
				</div>
				
				<div class="chart-container">
					<div class="cc-left">
						<p class="main-title">SOC电量</p>
						<p class="sub-title">信号数据说明</p>
						<ol>
							<li>SOC电量阈值为0% 到 100%</li>
						</ol>
					</div>
					<div class="cc-right">
						<div class="chart-item" id="chart_soc_power"></div>
					</div>
				</div>
				
				<div class="chart-container">
					<div class="cc-left">
						<p class="main-title">EPB状态</p>
						<p class="sub-title">信号数据说明 (Released)</p>
						<ol>
							<li>Applied</li>
							<li>正在释放或正在驻车状态</li>
							<li>发生保护不接受任何指令</li>
						</ol>
					</div>
					<div class="cc-right">
						<div class="chart-item" id="chart_epb_status"></div>
					</div>
				</div>
				
				<!--<div class="edit-signal">
					<p>编辑信号数据类 <i class="el-icon-edit-outline"></i></p>
				</div>-->
			</div>
		</el-drawer>
		<!--endregion 诊断分析-->
	</div>
</template>

<script>
	import Table from '@/components/Table'
	import {_ParkOption} from "../../api/global";
	import {_FindFaultPageData} from "../../api/dataAnalysis/fault";
	import * as echarts from "echarts";
	
	export default {
		components: {
			Table
		},
		
		data() {
			return {
				ParkOption: [],
				checked: true,
				
				searchParams: {
					parkId: "",
					carType: "",
					carNumber: "",
					dateRange: [],
					page: 1,
					pageSize: 20,
				},
				
				faultConfig: {
					compreErrorNum: 0,	//综合故障次数
					avgKmErrorNum: 0,		//平均每公里故障次数
					mainError: "无",		//主要故障项目
					mainErrorNum: 0,		//主要故障项目告警出现次数
					carList: [],				//车辆列表
				},
				
				carAnalysisConfig: {
					visible: false,
					size: "60%",
				},
				
				analysisDrawerSearchParams: {
					dateRange: []
				},
			};
		},
		
		mounted() {
			this.InitPageData();
		},
		methods: {
			//获取园区选项
			async GetParkOption() {
				let res = await _ParkOption();
				
				if (res.success) {
					this.ParkOption = res.data;
				}
			},
			
			async InitPageData() {
				await this.GetParkOption();
				await this.GetPageData();
			},
			
			// region 页面数据
			async GetPageData(){
				let jsonParams = {
					parkID: this.searchParams.parkId,
					carType: this.searchParams.carType,
					carNumber: this.searchParams.carNumber,
					beginDate: '',
					endDate: '',
					page: this.searchParams.page,
					pageSize: this.searchParams.pageSize,
				};
				
				if (this.searchParams.dateRange === null){
					this.searchParams.dateRange = [];
				}
				
				if (this.searchParams.dateRange.length === 2){
					jsonParams.beginDate = this.searchParams.dateRange[0];
					jsonParams.endDate = this.searchParams.dateRange[1];
				}
				
				let res = await _FindFaultPageData(jsonParams);
				
				if (res.success){
					this.faultConfig.compreErrorNum = (res.data.compreErrorNum) ? res.data.compreErrorNum : 0;
					this.faultConfig.avgKmErrorNum = (res.data.avgKmErrorNum) ? res.data.avgKmErrorNum : 0;
					this.faultConfig.mainError = (res.data.mainError) ? res.data.mainError : '无';
					this.faultConfig.mainErrorNum = (res.data.mainErrorNum) ? res.data.mainErrorNum : 0;
					
					this.faultConfig.carList = res.data.carList;
				}
			},
			// endregion 页面数据
			
			// region 车辆诊断分析
			OpenCarAnalysisDrawer(){
				this.carAnalysisConfig.visible = true;
				
				this.$nextTick(() => {
					this.InitChart();
				})
			},
			
			// region 图表
			InitChart(){
				this.SetChartSocPower();
				this.SetChartEpbStatus();
			},
			
			SetChartSocPower(){
				let xData = ['10-28 15:52:20', '10-28 15:52:30', '10-28 15:52:40', '10-28 15:52:50'];
				let yData = [7, 20, 13, 50];
				let objChart = echarts.init(document.getElementById('chart_soc_power'));
				this.DrawChart(objChart, "SOC电量", xData, yData);
			},
			
			SetChartEpbStatus(){
				let xData = ['10-28 09:33:12', '10-28 09:36:43', '10-28 09:42:40', '10-28 09:58:34'];
				let yData = [44, 6, 32, 17];
				let objChart = echarts.init(document.getElementById('chart_epb_status'));
				this.DrawChart(objChart, "EPB状态", xData, yData);
			},
			
			//绘制图表
			DrawChart(chart, text, xDate, yDate) {
				chart.setOption({
					
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: xDate,	//x轴：日期
						axisLabel: {
							interval: 0, //X轴信息全部展示
							textStyle: {
								align: 'center',
							}
						}
					},
					yAxis: {
						type: 'value',
					},
					grid: {
						top: '20', // 等价于 y: '16%'
						left: '30',
						right: '50',
						bottom: '10',
						containLabel: true
					},
					series: [{
						name: text,
						data: yDate,	//y轴：值
						type: 'line',
						smooth: true,
						stack: 'Total',
					}]
				})
			}
			// endregion 图表
			// endregion 车辆诊断分析
			
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .el-card__body {padding: 15px;}
	
	.analysis {
		height: 100px;
		background-color: #fff;
		
		.analyAfter, .analyAfterLast {
			text-align: center; position: relative; top: 20px; height: 60px;
			display: flex; flex-direction: column; justify-content: space-around;
			
			
			div:first-child {
				font-size: 14px; color: #666666;
			}
			
			div:last-child {
				font-size: 20px; color: #333333; font-weight: 600;
			}
		}
		
		.analyAfter:before {
			content: ""; position: absolute; width: 1px; height: 35px; right: 0px; background-color: #d4d7d9;
		}
	}
	
	.car-list{
		overflow: hidden;
		
		.item{margin-bottom: 10px;}
		
		.list-title{
			font-size: 16px; color: #333; font-weight: bold;
			
			span{padding: 0 2px; background-color: #377bde; margin-right: 5px;}
		}
	}
	
	.car-info-container {
		width: 100%; text-align: center;  background-color: #fff !important;
		
		.left{
			width: 30%; overflow: hidden; float: left;
			
			img { width: 100%; height: auto; }
		}
		
		.center{
			width: 45%; overflow: hidden; float: left; margin-left: 10px;
			
			.title{color: #999; font-size: 12px; text-align: right; padding: 2px 0;}
			.content{color: #333; font-size: 12px; text-align: left; padding: 2px 0;}
		}
		
		.right{
			width: 20%; overflow: hidden; float: right; margin-top: 20px;
			
			.btn-analysis{
				width: 80px; height: 36px; line-height: 38px; padding-left: 16px; font-size: 14px; cursor: pointer;
				color: #1683d9; background: #e7f2fb url("../../assets/images/diagnosis.png") no-repeat 10px center;
				background-size: 15px auto; border-radius: 3px; -webkit-border-radius: 3px; -moz-border-radius: 3px;
			}
		}
	}
	
	.drawer-container{
		width: 100%; overflow: hidden;
		
		.item-one{ height: 150px;}
		.item-two{ height: 42px; }
		.content-item{
			width: 100%; border-bottom: 1px solid #d4d7d9;
			
			.left-title{font-size: 16px; color: #666; padding: 10px; margin: 0;}
			.left-content{font-size: 14px; color: #333; padding: 0 10px 10px 10px; margin: 0;}
		}
		
		.dc-left{
			width: 178px; overflow: hidden; background-color: #f5f8fa; border: 1px solid #d4d7d9; border-bottom: 0;
			float: left;
		}
		
		.dc-center{
			width: 460px; overflow: hidden; border-right: 1px solid #d4d7d9; border-top: 1px solid #d4d7d9; float: left;
			
			.car-img{
				width: 190px; height: 150px; overflow: hidden; text-align: center; float: left;
				
				img{width: 200px; height: auto; margin-top: 15px;}
			}
			
			.car-info{
				width: 240px; overflow: hidden; float: left; margin-top: 28px;
				
				.car-name{font-size: 16px; color: #222; font-weight: bold;}
				.park-name{font-size: 14px; color: #333;}
				.car-del{
					width: 50px; height: 18px; font-size: 12px; position: absolute;
					margin-top: -105px; margin-left: 218px; color: #aaa; cursor: pointer;
				}
			}
		}
	}
	
	.chart-container{
		width: 639px; overflow: hidden; clear: both; border: 1px solid #d4d7d9; border-top: 0;
		
		.cc-left{
			width: 178px; height: 150px; overflow: hidden; background-color: #f5f8fa; border: 1px solid #d4d7d9; border-bottom: 0;
			border-top: 0; float: left; border-left: 0;
			
			.main-title{font-size: 16px; color: #333; font-weight: bold; padding: 10px 0 5px 0; margin: 0; text-align: center;}
			.sub-title{font-size: 12px; font-weight: bold; color: #666; padding: 5px 10px 3px 10px; margin: 0;}
			
			ol{
				padding: 0 10px 0 25px; margin: 0;
				
				li{font-size: 12px; color: #999;}
			}
		}
		
		.cc-right{
			width: 460px; height: 150px; float: left;
			
			.chart-item{width: 100%; height: 150px;}
		}
	}
	
	.edit-signal{
		width: 178px; overflow: hidden; border: 1px solid #d4d7d9; border-top: 0; clear: both;
		background-color: #f5f8fa; cursor: pointer; padding-top: 10px; padding-bottom: 10px;
		
		p{font-size: 16px; color: #666; padding: 10px; margin: 0;}
	}
</style>
